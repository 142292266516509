"use client";

import { ISbStoryData } from "@storyblok/react/rsc";
import { useI18n } from "@wojo/localization";
import { Breadcrumbs } from "@wojo/web-components";
import escapeStringRegexp from "escape-string-regexp";

function truncateTitle(title: string | undefined | null): string {
    if (!title) {
        return "";
    }
    const maxWords = 3;
    const wordsList = title.split(" ");
    return wordsList.length > maxWords
        ? wordsList.slice(0, maxWords).join(" ") + "..."
        : wordsList.join(" ");
}

const getSeoName = (story: ISbStoryData) => {
    if (story.content.seo?.at(0)?.breadcrumbName) {
        return story.content.seo[0].breadcrumbName;
    }

    if (story.content.title) {
        return truncateTitle(story.content.title);
    }

    if (story.content.name) {
        return truncateTitle(story.content.name);
    }

    return null;
};

const getPageLink = (slug: string): string => {
    const marketFolder = process.env.NEXT_PUBLIC_STORYBLOK_MARKET_FOLDER;
    const safeMarketFolder = escapeStringRegexp(marketFolder || "");
    return marketFolder
        ? slug.replace(new RegExp(`^${safeMarketFolder}`), "")
        : slug;
};

type BlogBreadcrumbsProps = {
    currentStory: ISbStoryData;
};

export const BlogBreadcrumbs: React.FC<BlogBreadcrumbsProps> = ({
    currentStory,
}) => {
    const { blog } = useI18n("blog");
    const blogPath = process.env.NEXT_PUBLIC_BLOG_FOLDER || "blog";
    const pageTitle = getSeoName(currentStory);
    const blogCategoryBreadcrumbItem = currentStory.content.category?.name
        ? [
              {
                  link: `/${getPageLink(
                      currentStory.content.category.full_slug,
                  )}`,
                  text: currentStory.content.category.name || "",
              },
          ]
        : [];
    const pageBreadcrumbItem = pageTitle
        ? [
              {
                  link: currentStory.full_slug,
                  text: pageTitle,
              },
          ]
        : [];

    return (
        <Breadcrumbs
            items={[
                {
                    link: "/",
                    text: blog.home.l(),
                },
                {
                    link: `/${getPageLink(blogPath)}`,
                    text: blog.blogHome.l(),
                },
                ...blogCategoryBreadcrumbItem,
                ...pageBreadcrumbItem,
            ]}
        />
    );
};
