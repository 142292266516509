"use client";

import { useSuspenseQuery } from "@apollo/client";
import { ISbStoryData } from "@storyblok/react/rsc";
import { Breadcrumbs } from "@wojo/web-components";
import escapeStringRegexp from "escape-string-regexp";
import {
    BusinessCode,
    ContentAncestorsDocument,
    ContentAncestorsQuery,
    ContentAncestorsQueryVariables,
} from "generated/graphql";

const getPageLink = (slug: string): string => {
    const marketFolder = process.env.NEXT_PUBLIC_STORYBLOK_MARKET_FOLDER;
    const safeMarketFolder = escapeStringRegexp(marketFolder || "");
    return marketFolder
        ? slug.replace(new RegExp(`^${safeMarketFolder}`), "")
        : slug;
};

const getSeoName = (story: ISbStoryData): string => {
    return story.content.seo?.at(0)?.breadcrumbName || story.name || "";
};

type ContentBreadcrumbsProps = {
    currentStory: ISbStoryData;
};

export const ContentBreadcrumbs: React.FC<ContentBreadcrumbsProps> = ({
    currentStory,
}) => {
    const { data } = useSuspenseQuery<
        ContentAncestorsQuery,
        ContentAncestorsQueryVariables
    >(ContentAncestorsDocument, {
        variables: {
            businessCode: process.env.NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
            fullSlug: currentStory.full_slug,
            language: process.env.NEXT_PUBLIC_STORYBLOK_LANGUAGE || "default",
        },
        context: {
            fetchOptions: { next: { revalidate: 900 } },
            includeCookie: false,
        },
    });
    const parsedSlug = currentStory.full_slug;
    const orderedContentAncestors = [...data.contentAncestors].reverse();
    const ancestoryItems = orderedContentAncestors.map((ancestor) => ({
        link: getPageLink(ancestor.fullSlug),
        text: ancestor.name,
    }));

    return (
        <Breadcrumbs
            items={[
                {
                    link: "/",
                    text: "Home",
                },
                ...ancestoryItems,
                {
                    link: parsedSlug,
                    text: getSeoName(currentStory),
                },
            ]}
        />
    );
};
