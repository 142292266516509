import cn from "clsx";
import { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { BreadcrumbItem, BreadcrumbItemProps } from "./BreadcrumbItem";
import styles from "./Breadcrumbs.module.scss";

type BreadcrumbDropdownProps = {
    /**
     * Breadcrumb items array
     */
    items: BreadcrumbItemProps[];
    /**
     * Width of the last breadcrumb
     */
    lastBreadcrumbWidth?: string;
    /**
     * Number of breadcrumbs to show in dropdown for truncation
     */
    dropdownCount?: number;
};

const DropdownMenu: React.FC<{
    items: BreadcrumbItemProps[];
    isDropdownOpen: boolean;
    setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ items, isDropdownOpen, setIsDropdownOpen }) => {
    const handleTabOver = (event: React.KeyboardEvent) => {
        if (event.key === "Tab") {
            setIsDropdownOpen(false);
        }
    };

    return (
        <ol
            className={cn(styles["dropdown-menu"], {
                [styles["dropdown-menu--open"]]: isDropdownOpen,
            })}
        >
            {items.map((item, index) => (
                <BreadcrumbItem
                    key={item.link}
                    {...item}
                    showSeparator={false}
                    closeDropdownOnLastTabOver={
                        index === items.length - 1 ? handleTabOver : undefined
                    }
                    className={styles["dropdown-item"]}
                />
            ))}
        </ol>
    );
};

export const BreadcrumbDropdown: React.FC<BreadcrumbDropdownProps> = ({
    items,
    lastBreadcrumbWidth,
    dropdownCount,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const itemsToShowInPopover = items.slice(1, dropdownCount);
    const remainingItems = items.slice(dropdownCount);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <>
            <BreadcrumbItem
                key={items[0].link}
                {...items[0]}
                showSeparator={true}
            />
            <li key={"more"} className={styles["breadcrumb-item"]}>
                <button
                    aria-label={"View more links"}
                    aria-expanded={isDropdownOpen}
                    className={cn(styles.link, styles["link--more"])}
                    onClick={toggleDropdown}
                >
                    ...
                </button>
                <div className={styles["icon-wrapper"]}>
                    <FaChevronRight aria-hidden={true} />
                </div>
                {
                    <DropdownMenu
                        items={itemsToShowInPopover}
                        isDropdownOpen={isDropdownOpen}
                        setIsDropdownOpen={setIsDropdownOpen}
                    />
                }
            </li>

            {remainingItems.map((item, index) => (
                <BreadcrumbItem
                    key={item.link}
                    {...item}
                    showSeparator={index < remainingItems.length - 1}
                    lastBreadcrumbWidth={
                        index == remainingItems.length - 1
                            ? lastBreadcrumbWidth
                            : undefined
                    }
                    isCurrentPage={index == remainingItems.length - 1}
                />
            ))}
        </>
    );
};
