export function calculateLastBreadcrumbWidth(
    breadcrumbsRef: React.MutableRefObject<HTMLOListElement | null>,
    containerRef: React.MutableRefObject<HTMLDivElement | null>,
) {
    if (breadcrumbsRef.current && containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        // Calculate the total width of all breadcrumb items except the last one
        let totalWidthExceptLast = 0;
        const breadcrumbItems = breadcrumbsRef.current.children;
        for (let i = 0; i < breadcrumbItems.length - 1; i++) {
            totalWidthExceptLast += (breadcrumbItems[i] as HTMLElement)
                .offsetWidth;
        }
        // Calculate the maximum width for the last breadcrumb item
        const maxWidthForLastItem = containerWidth - totalWidthExceptLast;

        const rootFontSize = parseFloat(
            getComputedStyle(document.documentElement).fontSize,
        );
        const maxWidthForLastItemInRem = maxWidthForLastItem / rootFontSize;
        return `${maxWidthForLastItemInRem}rem`;
    }
    return null;
}

/**
 * Calculate the breadcrumbs truncation
 * @param breadcrumbsRef
 * @param containerRef
 * @param originalBreadcrumbs
 * @param breadcrumbs
 */
export function calculateBreadcrumbsTruncation(
    breadcrumbsRef: React.MutableRefObject<HTMLOListElement | null>,
    containerRef: React.MutableRefObject<HTMLDivElement | null>,
    originalBreadcrumbs: HTMLElement | null,
    breadcrumbs: {
        link: string;
        text: string;
    }[],
) {
    if (breadcrumbsRef.current && containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        let breadcrumbsWidthCalc = 0;
        let dropdownCount = 0;

        // Calculate the total width of the breadcrumbs offscreen and then remove it
        // This is necessary to calculate the original width of the breadcrumbs when they are truncated already
        if (originalBreadcrumbs) {
            originalBreadcrumbs.style.position = "absolute";
            originalBreadcrumbs.style.left = "-9999px";
            originalBreadcrumbs.style.display = "block";
            originalBreadcrumbs.style.opacity = "0";
            originalBreadcrumbs.style.pointerEvents = "none";
            originalBreadcrumbs.setAttribute("aria-hidden", "true");
            document.body.appendChild(originalBreadcrumbs);
            breadcrumbsWidthCalc =
                originalBreadcrumbs.getBoundingClientRect().width;
            document.body.removeChild(originalBreadcrumbs);
        }

        // Move one breadcrumb at a time to the dropdown until the total width of the breadcrumbs and dropdown items is less than or equal to the width of the container
        while (
            breadcrumbsWidthCalc >= containerWidth &&
            dropdownCount < breadcrumbs.length - 1
        ) {
            const breadcrumb =
                breadcrumbsRef.current.children.item(dropdownCount);
            const breadcrumbWidth =
                breadcrumb?.getBoundingClientRect().width ?? 0;
            breadcrumbsWidthCalc -= breadcrumbWidth;
            dropdownCount++;
        }

        return {
            isDropdown: dropdownCount > 1,
            dropdownCount,
            breadcrumbWidth: breadcrumbsRef.current.offsetWidth,
        };
    }
}
