import { gtmSendEvent } from "@wojo/analytics";
import cn from "clsx";
import { CSSProperties } from "react";
import { FaChevronRight } from "react-icons/fa";
import styles from "./Breadcrumbs.module.scss";
import Link from "next/link";

export type BreadcrumbItemProps = {
    /**
     * Link to the breadcrumb
     */
    link: string;
    /**
     * Text to display for the breadcrumb
     */
    text: string;
    /**
     * Should show chevron after the breadcrumb
     */
    showSeparator?: boolean;
    /**
     * Width of the last breadcrumb
     */
    lastBreadcrumbWidth?: string;
    /**
     * Is this the current(last) breadcrumb
     */
    isCurrentPage?: boolean;
    /**
     * Function to call on key down
     * @param event
     */
    closeDropdownOnLastTabOver?: (event: React.KeyboardEvent) => void;
    /**
     * Additional class name
     */
    className?: string;
    /**
     * callback function for when a breadcrumb item is clicked
     */
    onBreadcrumbItemClickCustomEvent?: (text: string) => void;
};
export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
    link,
    text,
    showSeparator,
    isCurrentPage,
    closeDropdownOnLastTabOver,
    lastBreadcrumbWidth,
    className,
    onBreadcrumbItemClickCustomEvent,
}) => {
    return (
        <li className={cn(styles["breadcrumb-item"], className)}>
            {isCurrentPage ? (
                <span
                    className={cn(styles.link, styles["link--current"])}
                    aria-current={"page"}
                    style={
                        { "--max-width": lastBreadcrumbWidth } as CSSProperties
                    }
                >
                    {text}
                </span>
            ) : (
                <Link
                    href={link === "/" ? link : link.replace(/\/$|\/\/$/g, "")}
                    onClick={() => {
                        if (onBreadcrumbItemClickCustomEvent) {
                            onBreadcrumbItemClickCustomEvent(text);
                        } else {
                            gtmSendEvent({
                                event: "track",
                                ga4_object: "BREADCRUMBS",
                                ga4_action: "CLICKED",
                                clickText: text,
                            });
                        }
                    }}
                    className={styles.link}
                    onKeyDown={closeDropdownOnLastTabOver}
                >
                    {text}
                </Link>
            )}
            {showSeparator ? (
                <div className={styles["icon-wrapper"]}>
                    <FaChevronRight aria-hidden={true} />
                </div>
            ) : null}
        </li>
    );
};
